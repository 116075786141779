@use 'assets/styles/utils/helper' as h;
@use './theme.tokens';

.unmute.live-video-embed__button:hover {
  background-color: var(--live-video-embed-unmute-button-background-hover);
}

.unmute.live-video-embed__button:active {
  background-color: var(--live-video-embed-unmute-button-background-active);
}

.live-video-embed--msnbc-tve .live-video-embed__button {
  background-color: var(--live-video-embed-msnbc-tve-unmute-button-background);
  color: var(--black);
}

.live-video-embed--msnbc-tve .live-video-embed__button:hover {
  background-color: var(--live-video-embed-msnbc-tve-unmute-button-background-hover);
}

.live-video-embed--msnbc-tve .live-video-embed__button:active {
  background-color: var(--live-video-embed-msnbc-tve-unmute-button-background-active);
}

@media only screen and (max-width: 1365px) {
  :fullscreen > .unmuteTap,
  :-webkit-full-screen > .unmuteTap {
    display: none;
  }
}

.unmuteTap {
  cursor: pointer;
  position: absolute;
  display: flex;
  background: var(--unmuteBtnBg, #3061ff);
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color: var(--muteBtnText, #fff);
  top: var(--live-video-embed-unmute-button-top);
  left: var(--live-video-embed-unmute-button-left);
  z-index: h.get-z-index('sticky-video-player');
  height: var(--live-video-embed-unmute-button-height);
  width: var(--live-video-embed-unmute-button-width);
  border: 0;
  margin-bottom: var(--live-video-embed-unmute-button-margin-bottom);

  :global(.entretenimiento) &,
  :global(.shows) &,
  :global(.telemundo) & {
    background: var(--live-video-embed-tap-unmute-telemundo-background);
  }

  /* stylelint-disable scss/at-rule-no-unknown, at-rule-no-unknown  */
  @container infoLayer (min-width: 960px) {
    top: var(--live-video-embed-unmute-button-top-large);
    left: var(--live-video-embed-unmute-button-left-large);
  }

  :global(.select) & {
    background: var(--live-video-embed-tap-unmute-global-select-background);
  }

  .unmuteIcon {
    display: block;
    // adjust for whitespace on right of icon
    padding-left: var(--live-video-embed-unmute-button-icon-x-nudge);
    height: var(--live-video-embed-unmute-button-icon-height);
    width: var(--live-video-embed-unmute-button-icon-width);

    .volumeMutedIcon {
      height: 100%;
      width: 100%;
    }
  }

  &-msnbc {
    color: var(--black);
  }
}

body.today .unmuteTap .unmuteIcon img.volumeMutedIcon {
  filter: invert(1);
}

body.msnbc .unmuteTap .unmuteIcon img.volumeMutedIcon {
  filter: invert(1);
}
