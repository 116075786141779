@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.inline-video {
  position: relative;
  width: auto;
}

.inline-video--in-body {
  margin: h.rem(48px) 0 h.rem(48px) #{h.$outer-gutter * -1};
  width: calc(100% + (2 * #{h.$outer-gutter}));

  @include h.breakpoint-m {
    margin-left: calc(-4vw - (100% - 9 * (#{h.$gutter-tablet})) / 10);
    width: calc(100% + (2 * #{h.$gutter-tablet}) + (2 * (100% - 9 * (#{h.$gutter-tablet})) / 10));
  }

  @include h.breakpoint-l {
    clear: left;
    margin-left: 0;
    width: 100%;
  }

  @include h.breakpoint-x {
    margin-left: -100px;
    width: calc(100% + 100px);
  }
}

.inline-video__background {
  background: var(--black);
  padding-top: 56.25%; // 16x9 video ratio
  position: relative;
}

.inline-video__player {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.inline-video__info {
  height: var(--inline-video--info--height);
  padding: var(--inline-video--info--padding);
  background-image: var(--inline-video--info--background-image);
  color: var(--inline-video--info--color);
  left: 0;
  opacity: 1;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 500ms ease-in;
  width: 100%;
  z-index: 1;

  &--ad-playing {
    display: none;
  }

  &--info-hidden {
    opacity: 0;
    transition: opacity 500ms ease-out;
    transition-delay: 0ms, 500ms;
    transition-property: opacity, visibility;
    visibility: hidden;
  }
}

.inline-video__title {
  color: var(--inline-video--title-color);
  font-family: var(--inline-video--title--font-family);
  font-size: var(--inline-video--title--font-size);
  font-style: var(--inline-video--title--font-style);
  font-weight: var(--inline-video--title--font-weight);
  line-height: var(--inline-video--title--line-height);
  margin: var(--inline-video--title--margin);
  padding: var(--inline-video--title--padding);
  -webkit-font-smoothing: var(--inline-video--title--font-smoothing);

  a {
    pointer-events: all;
  }

  &--muted {
    margin: var(--inline-video--title--muted--margin);
  }
}

.inline-video__title-button {
  background-color: transparent;
  border: none;
  color: var(--inline-video--title-button--color);
  line-height: 1;
  margin: 0;
  padding: 0;
  text-align: left;
}

.inline-video__detail {
  display: var(--inline-video--detail--display);
  font-family: var(--inline-video--detail--font-family);
  font-size: var(--inline-video--detail--font-size);
  letter-spacing: var(--inline-video--detail--letter-spacing);
  line-height: var(--inline-video--detail--line-height);
  margin: var(--inline-video--detail--margin);
  text-transform: var(--inline-video--detail--text-transform);
  font-weight: var(--inline-video--detail--font-weight);

  @include t.constrained-height {
    display: none;
  }

  :not(:last-child)::after {
    // <space>/<space>
    content: '\00A0\002f\00A0';
  }

  .inline-video__title--muted + & {
    margin-left: var(--inline-video--detail--muted--margin-left, 0);
  }
}

.inline-video__play-button,
.play.inline-video__play-button { // override videoplayer component rules
  opacity: var(--inline-video--play-btn--opacity);
  height: var(--inline-video--play-btn--height);
  width: var(--inline-video--play-btn--width);
  border-radius: var(--inline-video--play-btn--border-radius);
  border: var(--inline-video--play-btn--border);

  .player:hover & {
    opacity: 1;
  }

  .icon {
    font-size: var(--inline-video--play-btn--icon--font-size);
  }

  @include t.constrained-height {
    bottom: var(--inline-video--play-btn--contrained--bottom);
    left: var(--inline-video--play-btn--contrained--left);
    position: absolute;
  }
}

// Extra specificity to override default styles
.inline-video .share,
.inline-video__info .inline-video__share {
  background-color: transparent;
  right: 0;
  pointer-events: all;
  transition: background-color 400ms ease 600ms;
  z-index: 10;

  @include h.breakpoint-m {
    height: h.rem(46px);
    width: h.rem(46px);
  }

  @include h.breakpoint-l {
    height: h.rem(60px);
    width: h.rem(60px);
  }

  @include h.breakpoint-x {
    height: h.rem(46px);
    width: h.rem(46px);

    .article-hero__video & {
      width: h.rem(67px);
      height: h.rem(67px);
    }
  }

  &--muted {
    margin-top: var(--inline-video--share-muted--margin-top);
  }

  // .inline-video__share-expander
  &-expander {
    @include h.breakpoint-m {
      height: h.rem(46px);
      width: h.rem(46px);
    }

    @include h.breakpoint-l {
      height: h.rem(60px);
      width: h.rem(60px);
    }

    @include h.breakpoint-x {
      height: h.rem(46px);
      width: h.rem(46px);

      .article-hero__video & {
        width: h.rem(67px);
        height: h.rem(67px);
      }
    }
  }

  // .inline-video__share-dot
  &-dot {
    top: h.rem(19px);
    left: h.rem(19px);

    @include h.breakpoint-m {
      top: h.rem(19px);
      left: h.rem(20px);
    }

    @include h.breakpoint-l {
      top: h.rem(27px);
      left: h.rem(27px);
    }

    @include h.breakpoint-x {
      top: h.rem(19px);
      left: h.rem(20px);

      .article-hero__video & {
        top: h.rem(29px);
        left: h.rem(31px);
      }
    }
  }

  // .inline-video__share-close
  &-close {
    @include h.breakpoint-m {
      top: h.rem(17px);
      left: h.rem(16px);
    }

    @include h.breakpoint-l {
      top: h.rem(22px);
      left: h.rem(22px);
    }

    @include h.breakpoint-x {
      top: h.rem(17px);
      left: h.rem(16px);

      .article-hero__video & {
        top: h.rem(26px);
        left: h.rem(28px);
      }
    }
  }

  // .inline-video__share-icon
  &-icon {
    @include h.breakpoint-m {
      line-height: var(--inline-video--share-icon--line-height);
    }
  }

  // .inline-video__share-list
  &-list {
    @include h.breakpoint-m {
      right: h.rem(45px);
      height: var(--inline-video--share-list--height);
    }

    @include h.breakpoint-l {
      right: h.rem(60px);
    }

    @include h.breakpoint-x {
      right: h.rem(46px);

      .article-hero__video & {
        right: h.rem(67px);
      }
    }
  }

  // .inline-video__share-expanded .inline-video__share-list
  &-expanded .inline-video__share-list {
    background-color: var(--inline-video--share-list--background-color);
    width: var(--inline-video--share-list--width);
  }
}

// type overrides
.liveBlog {
  .article-body .inline-video {
    margin: var(--inline-video--liveblog--margin);
  }

  .article-hero__video .inline-video__play-button {
    @include h.breakpoint-l {
      height: h.rem(100px);
      width: h.rem(150px);
    }
  }
}

.recipe-body__content {
  .inline-video--in-body {
    @include h.breakpoint-m {
      max-width: 100%;
      margin: h.rem(24px) auto;
    }

    @include h.breakpoint-x {
      max-width: 120%;
      margin: h.rem(24px) h.rem(-48px);
    }
  }

  .inline-video__detail {
    display: inline-block;
    line-height: h.rem(12px);
  }
}
