/* stylelint-disable ramen/import-helper-file */
// disabling rule since we're not using helper stuff/tokens in this file

.gridStackWrapper {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
}

.gridStackWrapper > * {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
}
