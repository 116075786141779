@use 'assets/styles/utils/helper' as h;
/* stylelint-disable no-descending-specificity */
/* stylelint-disable at-rule-no-unknown  */
/* stylelint-disable scss/at-rule-no-unknown  */
/* stylelint-disable property-no-unknown  */
.videoSlate {
  --video-slate-gamma-flag-height: 70px;
  --video-slate-gamma-flag-width: 124px;
  --video-slate-flag-max-height: 30%;
  --video-slate-flag-max-width: 30%;
  --video-slate-flag-default-opacity: 0.85;
  contain: content;
  container-type: inline-size;
  container-name: videoContainer;

  @include h.breakpoint-s-only {
    // Constrain if used on small thumbnail:
    --video-slate-gamma-flag-height: 54px;
    --video-slate-gamma-flag-width: 95px;
  }

  @include h.breakpoint-l-only {
    --video-slate-gamma-flag-height: 85px;
    --video-slate-gamma-flag-width: 150px;
  }

  :global(body.today) & {
    --video-slate-gamma-flag-height: #{h.rem(70px)};
    --video-slate-gamma-flag-width: #{h.rem(70px)};
    --video-slate-flag-max-height: #{h.rem(125px)};
    --video-slate-flag-max-width: #{h.rem(125px)};
    --video-slate-flag-default-opacity: 1;

    @include h.breakpoint-m {
      --video-slate-flag-border-radius: 50%;
      --video-slate-gamma-flag-height: #{h.rem(125px)};
      --video-slate-gamma-flag-width: #{h.rem(125px)};
    }
  }

  picture {
    pointer-events: none;
  }

  &:hover .play {
    background-color: rgb(48, 97, 255, 1);
  }
}

:global(.jw-settings-menu) {
  z-index: 1;
}

:global(.jw-icon[aria-label='Loading']),
:global(.jw-icon[aria-label='Cargando']),
:global(.jw-icon-display[aria-label='Reproducir']),
:global(.jw-icon-display[aria-label='Play']) {
  opacity: 0;
}

:global(.jw-flag-floating .jw-icon),
:global(.started.paused .jw-icon) {
  opacity: 1;
}

.spinnerLoader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; // used to ensure no animations go out of box on mobile
  pointer-events: none;

  &.isDismissed::after {
    animation: fade 300ms linear forwards;
  }
}

.inlinePlay {
  &.hasGradient {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 5.64%, rgba(0, 0, 0, 0.0001) 96.8%);
    position: absolute;
    bottom: 0;
    width: 100%;

    @container (min-width: 225px) {
      height: 92px;
    }

    @container (min-width: 280px) {
      height: 96px;
    }

    @container (min-width: 375px) {
      height: 108px;
    }
  }

  .buttonHeadlineContainer {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    container: buttonHeadlineContainer;

    @container (min-width: 225px) {
      padding-bottom: 12px;
      height: 48px;
    }

    @container (min-width: 374px) {
      height: 60px;

      @include h.breakpoint-s-only {
        height: 48px;
      }
    }

    .playButtonOuter {
      position: relative;
      margin: auto 0;

      @container (min-width: 225px) {
        padding-left: 12px;
      }
    }

    .playButtonContainer {
      .play {
        max-height: 40px;
        max-width: 40px;

        @include h.breakpoint-s-only {
          max-height: 36px;
          max-width: 54px;
        }

        @include h.breakpoint-m {
          @container (min-width: 225px) {
            max-height: 27px;
            max-width: 40px;
          }

          @container (min-width: 280px) {
            max-height: 36px;
            max-width: 54px;
          }

          @container (min-width: 374px) {
            max-height: 48px;
            max-width: 72px;
          }
        }
      }
    }

    .inlineTitle {
      display: none;

      @container (min-width: 225px) {
        color: #fff;
        margin: auto 12px;
        font-size: 16px;
        line-height: 16px;
        font-family: var(--founders-cond);
        font-weight: 600;
        /* stylelint-disable-next-line */
        display: -webkit-box;
        -webkit-line-clamp: 2; /* Number of lines  */
        -webkit-box-orient: vertical;
        -webkit-box-pack: center;
        overflow: hidden;
        text-overflow: hidden;
        text-align: left;
        position: relative;
        bottom: 0;
      }

      @container (min-width: 459px) {
        font-size: 20px;
        line-height: 20px;

        @include h.breakpoint-s-only {
          font-size: 16px;
          line-height: 16px;
        }
      }
    }
  }
}

.play {
  background-color: rgb(48, 97, 255, .85);
  height: var(--video-slate-gamma-flag-height);
  width: var(--video-slate-gamma-flag-width);
  pointer-events: none;
  transition: background-color 200ms linear;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1;
  position: relative;
  border-radius: var(--video-slate-flag-border-radius);
  border: var(--video-slate-flag-border);

  &--live {
    background-color: var(--video-slate-flag-live-background-color);
  }

  &--icon {
    display: var(--video-slate-flag-icon-display);
    fill: var(--video-slate-flag-icon-fill);
    height: var(--video-slate-flag-icon-height);
    width: var(--video-slate-flag-icon-width);
    margin-left: var(--video-slate-flag-icon-margin-left);
  }

  &::after {
    display: var(--video-slate-icon-after-display);
    clip-path: polygon(100% 50%, 0 0, 0 100%);
    content: '';
    width: 20%;
    height: 23.2%;
    background-color: var(--video-slate-icon-after-bkg-color);
    top: 38%;
    left: 43%;
    position: absolute;
  }

  @include h.breakpoint-m {
    // Constrain if used on small thumbnail:
    max-height: var(--video-slate-flag-max-height);
    max-width: var(--video-slate-flag-max-width);
  }
}

@container videoContainer (width < 459px) {
  .play {
    background-color: --video-slate-flag-live-background-color;
  }
}
