.newsLoader {
  path {
    transform-origin: bottom center;
    // set initial state so there's no FOUC
    opacity: 0;
    // various hacks to get GPU for Safari
    backface-visibility: hidden;
    perspective: 1000;
    // correct way to set GPU enhanced animations for modern browsers
    will-change: opacity, transform;
  }

  &:global(.animateIn) {
    path {
      opacity: 0;

      &:nth-child(1) {
        animation: fadeIn 300ms linear forwards;
      }

      &:nth-child(2) {
        animation: featherIn2 300ms ease-in-out forwards, fadeIn 300ms linear forwards;
      }

      &:nth-child(3) {
        animation: featherIn3 300ms ease-in-out forwards, fadeIn 300ms linear forwards;
      }

      &:nth-child(4) {
        animation: featherIn4 300ms ease-in-out forwards, fadeIn 300ms linear forwards;
      }

      &:nth-child(5) {
        animation: featherIn5 300ms ease-in-out forwards, fadeIn 300ms linear forwards;
      }

      &:nth-child(6) {
        animation: featherIn6 300ms ease-in-out forwards, fadeIn 300ms linear forwards;
      }
    }
  }

  &:global(.isLoading) {
    // stylelint-disable-next-line no-descending-specificity
    path {
      opacity: .3;

      &:nth-child(1) {
        transform: translate3d(0, 0, 0);
        animation: blink 3000ms linear infinite forwards;
      }

      &:nth-child(2) {
        transform: translate3d(0, 0, 0);
        animation: blink 3000ms linear 200ms infinite forwards;
      }

      &:nth-child(3) {
        transform: translate3d(0, 0, 0);
        animation: blink 3000ms linear 400ms infinite forwards;
      }

      &:nth-child(4) {
        transform: translate3d(0, 0, 0);
        animation: blink 3000ms linear 600ms infinite forwards;
      }

      &:nth-child(5) {
        transform: translate3d(0, 0, 0);
        animation: blink 3000ms linear 800ms infinite forwards;
      }

      &:nth-child(6) {
        transform: translate3d(0, 0, 0);
        animation: blink 3000ms linear 1000ms infinite forwards;
      }
    }
  }

  &:global(.animateOut) {
    // stylelint-disable-next-line no-descending-specificity
    path {
      &:nth-child(-n+6) {
        animation: fadeOut 300ms linear forwards;
      }

      &:nth-child(1) {
        animation: featherOut1 300ms linear forwards, fadeOut 300ms linear forwards;
      }

      &:nth-child(2) {
        animation: featherOut2 300ms linear forwards, fadeOut 300ms linear forwards;
      }

      &:nth-child(3) {
        animation: featherOut3 300ms linear forwards, fadeOut 300ms linear forwards;
      }

      &:nth-child(4) {
        animation: featherOut4 300ms linear forwards, fadeOut 300ms linear forwards;
      }

      &:nth-child(5) {
        animation: featherOut5 300ms linear forwards, fadeOut 300ms linear forwards;
      }

      &:nth-child(6) {
        animation: fadeOut 300ms linear forwards;
      }
    }
  }
}

@keyframes blink {
  0%,
  24%,
  71%,
  100% {
    opacity: .3;
  }

  25%,
  70% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  to {
    opacity: .3;
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}

// Feather IN keyframes
// skip 1 since it doesn't move
@keyframes featherIn2 {
  from {
    transform: rotate(-30deg) translate3d(5px, 10px, 0);
  }

  to {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }
}

@keyframes featherIn3 {
  from {
    transform: rotate(-60deg) translate3d(0, 8px, 0);
  }

  to {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }
}

@keyframes featherIn4 {
  from {
    transform: rotate(-80deg) translate3d(-10px, 6px, 0);
  }

  to {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }
}

@keyframes featherIn5 {
  from {
    transform: rotate(-110deg) translate3d(-12px, 0, 0);
  }

  to {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }
}

@keyframes featherIn6 {
  from {
    transform: rotate(-145deg) translate3d(0, -5px, 0);
  }

  to {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }
}

// Feather OUT keyframes
// skip 6 since it doesn't move
@keyframes featherOut1 {
  from {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }

  to {
    transform: rotate(145deg) translate3d(0, -5px, 0);
  }
}

@keyframes featherOut2 {
  from {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }

  to {
    transform: rotate(110deg) translate3d(12px, 0, 0);
  }
}

@keyframes featherOut3 {
  from {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }

  to {
    transform: rotate(80deg) translate3d(10px, 6px, 0);
  }
}

@keyframes featherOut4 {
  from {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }

  to {
    transform: rotate(60deg) translate3d(0, 8px, 0);
  }
}

@keyframes featherOut5 {
  from {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }

  to {
    transform: rotate(30deg) translate3d(-5px, 10px, 0);
  }
}
