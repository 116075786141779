@use 'assets/styles/utils/helper' as h;

.videoPlayer__unmute {
  --white: #fff;
  --black: #000;
  --live-video-embed-unmute-button-font-family: var(--founders-cond);
  --live-video-embed-unmute-button-background-hover: #fd6f6f;
  --live-video-embed-unmute-button-background-active: #e91717;
  --live-video-embed-msnbc-tve-unmute-button-background: #ffbe03;
  --live-video-embed-msnbc-tve-unmute-button-background-hover: #ffd14d;
  --live-video-embed-msnbc-tve-unmute-button-background-active: #f19d01;
  --live-video-embed-tap-unmute-telemundo-background: #c31010;
  --live-video-embed-tap-unmute-global-select-background: #0477c9;
  --live-video-embed-tapmini-hover-background: #82aeff;
  --live-video-embed-tapmini-active-background: #1b4698;
  --muteBtnLiveColor: #fff;
  --muteBtnLiveBgColor: #fa3a3a;
  --muteBtnLiveBgHover: #ff907b;
  --muteBtnLiveBgActive: #e73e2e;
  --live-video-embed-tapmini-msnbc-hover-background: #ffd24f;
  --live-video-embed-tapmini-msnbc-active-background: #ffde81;
  --live-video-embed-unmute-button-height: #{h.rem(44px)};
  --live-video-embed-unmute-button-width: var(--live-video-embed-unmute-button-height);
  // this svq icon has 23/17 aspect ratio
  --live-video-embed-unmute-button-icon-width: calc(var(--live-video-embed-unmute-button-width) / 2);
  --live-video-embed-unmute-button-icon-height: calc(var(--live-video-embed-unmute-button-icon-width) * 17 / 23);
  // our video-muted svg has a little white space on the right, this centers
  --live-video-embed-unmute-button-icon-x-nudge: calc(var(--live-video-embed-unmute-button-icon-width) * 2.5 / 23);
  --live-video-embed-top-left: #{h.rem(10px)};
  --live-video-embed-unmute-button-top: var(--live-video-embed-top-left);
  --live-video-embed-unmute-button-left: var(--live-video-embed-top-left);
  --live-video-embed-unmute-button-top-large: var(--live-video-embed-top-left);
  --live-video-embed-unmute-button-left-large: var(--live-video-embed-top-left);
  --live-video-embed-unmute-button-padding-top: 0;
  --live-video-embed-unmute-button-margin-bottom: 0;

  body.news & {
    --unmuteBtnBg: #3061ff;
  }

  body.deportes &,
  body.localstationdeportes &,
  body.noticias & {
    --unmuteBtnBg: #c31010;
  }

  body.mach & {
    --unmuteBtnBg: #5c00d8;
  }

  body.better & {
    --unmuteBtnBg: #21d1aa;
  }

  body.globalcitizen & {
    --unmuteBtnBg: #7eaaad;
  }

  body.msnbc & {
    --unmuteBtnBg: #ffbe03;
    --muteBtnText: #000;
    --muteBtnLiveBgColor: #ffbe03;
    --muteBtnLiveBgHover: #ffd14d;
    --muteBtnLiveBgActive: #f19d01;
    --muteBtnLiveColor: #000;
  }

  body.think & {
    --unmuteBtnBg: #ff5042;
  }

  body.entretenimiento &,
  body.shows &,
  body.telemundo & {
    --unmuteBtnBg: #c31010;
  }

  body.today & {
    --live-video-embed-unmute-button-font-family: var(--secondary-font);
    --unmuteBtnBg: var(--accent-orange);
  }
}
