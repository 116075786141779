@use 'assets/styles/utils/helper' as h;

.infoLayer {
  --video-player--title--font-family: var(--founders-cond);
  --video-player--title--font-size: #{h.rem(18px)};
  /* stylelint-disable unit-no-unknown  */
  @supports (font-size: 1cqh) {
    --video-player--title--font-size: clamp(1rem, 6cqh, 1.7rem);
  }

  --video-player--title--font-weight: 400;
  --video-player--title--line-height: var(--leading-125);
  --video-player--title--color: var(--white);
  --video-player--detail--color: var(--white);
  --video-player--detail--font-family: var(--founders-mono);
  --video-player--detail--font-size: var(--text-12);
  --video-player--detail--letter-spacing: -0.04em;
  --video-player--detail--line-height: var(--text-20);
  --video-player--detail--text-transform: uppercase;
  --video-player--detail--font-weight: 400;
  --video-player--text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);

  :global(body.today) & {
    --video-player--title--font-family: var(--secondary-font);
    --video-player--title--font-weight: 700;
    --video-player--detail--font-family: var(--secondary-font);
    --video-player--detail--font-weight: 700;
    --video-player--detail--font-size: var(--text-12);
  }
}
