@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

/* stylelint-disable at-rule-no-unknown  */
/* stylelint-disable scss/at-rule-no-unknown  */
/* stylelint-disable no-descending-specificity  */

.share {
  height: h.rem(40px);
  position: absolute;
  right: 0;
  top: 0;
  width: h.rem(40px);
  z-index: 1;

  @container infoLayer (min-width: 459px) {
    width: h.rem(60px);
    height: h.rem(60px);
  }
}

.shareCheckbox {
  display: none;
}

.shareList {
  background-color: var(--social-share-inline--share-list--bkg);
  color: var(--social-share-inline--share-list--color);
  height: var(--social-share-inline--share-list--height);
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: h.rem(40px);
  top: 0;
  transition: width 0.3s 0.3s;
  width: 0;
  pointer-events: all;

  @container infoLayer (min-width: 459px) {
    right: h.rem(60px);
  }
}

.shareLink {
  margin: var(--social-share-inline--link--margin);
  padding: var(--social-share-inline--link--padding);
  float: left;
  font-size: var(--social-share-inline--link--font-size);
  text-align: center;
  display: block;
  width: var(--social-share-inline--link--width);
  transform: var(--social-share-inline--link--transform);
  transition: var(--social-share-inline--link--transition);
  background-color: var(--social-share-inline--link--bkg);
  border: var(--social-share-inline--link--border);
  border-radius: var(--social-share-inline--link--border-radius);

  &:nth-of-type(1) {
    transition-delay: 0s;
  }

  &:nth-of-type(2) {
    transition-delay: var(--social-share-inline--link--delay-2);
  }

  &:nth-of-type(3) {
    transition-delay: var(--social-share-inline--link--delay-3);
  }

  &:nth-of-type(4) {
    transition-delay: var(--social-share-inline--link--delay-4);
  }
}

.shareIcon {
  line-height: var(--social-share-inline--icon--line-height);
}

.shareExpander {
  position: absolute;
  right: 0;
  top: 0;
  width: h.rem(40px);
  height: h.rem(40px);
  cursor: pointer;
  pointer-events: all;

  @container infoLayer (min-width: 459px) {
    width: h.rem(60px);
    height: h.rem(60px);
  }
}

.shareDot,
.shareDot::before,
.shareDot::after {
  position: absolute;
  width: h.rem(3px);
  height: h.rem(3px);
  border-radius: h.rem(3px);
  background-color: var(--social-share-inline--dot--bkg);

  @container infoLayer (min-width: 459px) {
    width: h.rem(5px);
    height: h.rem(5px);
    border-radius: h.rem(5px);
  }
}

.shareDot {
  top: h.rem(19px);
  left: h.rem(19px);
  transition: opacity 0.2s 0.6s;

  @container infoLayer (min-width: 459px) {
    top: h.rem(27px);
    left: h.rem(27px);
  }

  &::before,
  &::after {
    content: '';
  }

  &::before {
    right: h.rem(5px);
    transition: right .2s 0.6s ease-out;

    @container infoLayer (min-width: 459px) {
      right: h.rem(10px);
    }
  }

  &::after {
    left: h.rem(5px);
    transition: left .2s 0.6s ease-out;

    @container infoLayer (min-width: 459px) {
      left: h.rem(10px);
    }
  }
}

.shareClose {
  position: absolute;
  width: h.rem(12px);
  height: h.rem(12px);
  top: h.rem(14px);
  left: h.rem(14px);
  transition: transform 0.3s 0.4s;
  transform: scaleX(0);

  @container infoLayer (min-width: 459px) {
    width: h.rem(15px);
    height: h.rem(15px);
    top: h.rem(22px);
    left: h.rem(22px);
  }
}

.shareLine {
  stroke: var(--social-share-inline--line--stroke);
  stroke-width: var(--social-share-inline--line--stroke-width);
  transform-origin: 50% 50%;

  &:first-child {
    transition: transform 0.3s 0.1s;
    transform: rotate(135deg);
  }

  &:last-child {
    transform: rotate(45deg);
    transition: transform 0.3s 0.1s;
  }
}

.hoverEffect:hover {
  transition: background 0.4s;
  background-color: var(--social-share-inline--hover-bkg);
}

.shareExpanded {
  & > .shareExpander {
    & > .shareDot {
      opacity: 0;
      transition: opacity 0.2s 0.2s;
    }

    & > .shareDot::before {
      right: 0;
      transition: right .2s ease-out;
    }

    & > .shareDot::after {
      left: 0;
      transition: left .2s ease-out;
    }

    .shareClose {
      transition: transform 0.2s 0.2s;
      transform: scaleX(1);
    }

    .shareLine:first-child {
      transform: rotate(0deg);
      transition: transform 0.4s 0.6s;
    }

    .shareLine:last-child {
      transition: transform 0.2s 0.6s;
      transform: rotate(0deg);
    }
  }

  .shareList {
    width: var(--social-share-inline--list-expanded--width);
    transition: width 0.3s 0.6s;

    & > .shareLink {
      transform: translateY(0);
      transition: transform 0.3s 0.9s ease-in-out, opacity 0.25s ease-in;

      &:nth-of-type(1) {
        transition-delay: var(--social-share-inline--link-expanded-delay-1);
      }

      &:nth-of-type(2) {
        transition-delay: var(--social-share-inline--link-expanded-delay-2);
      }

      &:nth-of-type(3) {
        transition-delay: var(--social-share-inline--link-expanded-delay-3);
      }

      &:nth-of-type(4) {
        transition-delay: var(--social-share-inline--link-expanded-delay-4);
      }

      &:hover {
        color: var(--social-share-inline--share-list--color-hover);
        background-color: var(--social-share-inline--link--bkg-hover);
      }
    }

    &:hover .shareLink:not(:hover) {
      opacity: var(--social-share-inline--link--not-hover--opacity);
      transition: opacity .25s ease-in;
    }
  }
}
