.defaultLoader {
  width: 90px;
  display: flex;
  justify-content: space-between;
  overflow: visible;

  .dot {
    flex-grow: 0;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 100%;
    margin: 0 8.5px;
    // set initial state so there's no FOUC
    opacity: 0;
    will-change: opacity, transform;
    // various hacks to get GPU
    backface-visibility: hidden;
    perspective: 1000;
  }

  &:global(.animateIn) {
    .dot {
      opacity: 0;

      &:nth-child(1) {
        animation: fadeIn1 900ms linear forwards;
      }

      &:nth-child(2) {
        animation: fadeIn2 900ms linear forwards;
      }

      &:nth-child(3) {
        animation: fadeIn3 900ms linear forwards;
      }
    }
  }

  &:global(.isLoading) {
    // stylelint-disable-next-line no-descending-specificity
    .dot {
      opacity: .3;

      &:nth-child(1) {
        animation: blinkAndGrow 2500ms linear 50ms infinite forwards;
      }

      &:nth-child(2) {
        animation: blinkAndGrow 2500ms linear 300ms infinite forwards;
      }

      &:nth-child(3) {
        animation: blinkAndGrow 2500ms linear 550ms infinite forwards;
      }
    }
  }

  &:global(.animateOut) {
    // stylelint-disable-next-line no-descending-specificity
    .dot {
      &:nth-child(1) {
        animation: dropOut 300ms linear forwards;
      }

      &:nth-child(2) {
        animation: dropOut 300ms linear 100ms forwards;
      }

      &:nth-child(3) {
        animation: dropOut 300ms linear 200ms forwards;
      }
    }
  }
}

@keyframes blinkAndGrow {
  0%,
  75%,
  100% {
    opacity: .3;
    transform: scale(.95);
  }

  25%,
  50% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes dropOut {
  from {
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(200px);
  }
}

@keyframes fadeIn1 {
  0% {
    opacity: 0;
    transform: scale(.95);
  }

  33%,
  100% {
    opacity: .3;
    transform: scale(.95);
  }
}

@keyframes fadeIn2 {
  0%,
  33% {
    opacity: 0;
    transform: scale(.95);
  }

  33%,
  66%,
  100% {
    opacity: .3;
    transform: scale(.95);
  }
}

@keyframes fadeIn3 {
  0%,
  33% {
    opacity: 0;
    transform: scale(.95);
  }

  66%,
  100% {
    opacity: .3;
    transform: scale(.95);
  }
}
