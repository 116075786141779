.todayLoader {
  overflow: hidden;

  path {
    // set initial state so there's no FOUC
    opacity: 0;
    // various hacks to get GPU
    backface-visibility: hidden;
    perspective: 1000;
    // correct way to set GPU enhanced animations for modern browsers
    will-change: opacity, transform;
  }

  &:global(.animateIn) {
    path {
      opacity: 0;
      // safari bug: animation can't be written in shorthand
      animation-name: fadeIn;
      animation-duration: 200ms;
      animation-fill-mode: forwards;
      animation-timing-function: linear;
      animation-iteration-count: 1;

      &:nth-child(1) {
        animation-delay: 100ms;
      }

      &:nth-child(2) {
        animation-delay: 400ms;
      }

      &:nth-child(3) {
        animation-delay: 700ms;
      }
    }
  }

  &:global(.isLoading) {
    // stylelint-disable-next-line no-descending-specificity
    path {
      opacity: .3;
      // safari bug: animation can't be written in shorthand
      animation-name: blink;
      animation-duration: 3000ms;
      animation-fill-mode: forwards;
      animation-timing-function: linear;
      animation-iteration-count: infinite;

      &:nth-child(1) {
        animation-delay: 0ms;
      }

      &:nth-child(2) {
        animation-delay: 500ms;
      }

      &:nth-child(3) {
        animation-delay: 1000ms;
      }
    }
  }

  &:global(.animateOut) {
    // stylelint-disable-next-line no-descending-specificity
    path {
      // safari bug: animation can't be written in shorthand
      animation-name: dropOut;
      animation-duration: 400ms;
      animation-fill-mode: forwards;
      animation-timing-function: ease-out;
      animation-iteration-count: 1;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        animation-delay: 0ms;
      }
    }
  }
}

@keyframes dropOut {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(100px);
  }
}

@keyframes blink {
  0%,
  24%,
  71%,
  100% {
    opacity: .3;
  }

  25%,
  70% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: .3;
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}
