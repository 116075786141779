@use 'assets/styles/utils/helper' as h;

/* stylelint-disable at-rule-no-unknown  */
@mixin constrained-height {
  @media screen and (max-height: h.rem(275px)) {
    @content;
  }
}
/* stylelint-enable */

.inline-video {
  --inline-video--info--height: #{h.rem(120px)};
  --inline-video--info--padding: #{h.rem(16px 48px 0 16px)};
  --inline-video--info--color: var(--white);
  --inline-video--info--background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 96.8%);
  --inline-video--title-color: inherit;
  --inline-video--title--font-family: var(--founders-cond);
  --inline-video--title--font-size: var(--text-18);
  --inline-video--title--font-style: normal;
  --inline-video--title--font-weight: 400;
  --inline-video--title--font-smoothing: antialiased;
  --inline-video--title--line-height: 1.25;
  --inline-video--title--margin: 0;
  --inline-video--title--padding: 0;
  --inline-video--title--muted--margin: #{h.rem(44px) 0 0 0};
  --inline-video--title-button--color: var(--white);
  --inline-video--detail--display: none;
  --inline-video--detail--font-family: var(--founders-mono);
  --inline-video--detail--font-size: var(--text-12);
  --inline-video--detail--letter-spacing: -0.04em;
  --inline-video--detail--line-height: var(--text-20);
  --inline-video--detail--text-transform: uppercase;
  --inline-video--detail--font-weight: 400;
  --inline-video--share-list--background-color: var(--default-color);
  --inline-video--share-list--width: #{h.rem(125px)};
  --inline-video--share-muted--margin-top: #{h.rem(44px)};
  --inline-video--play-btn--height: #{h.rem(60px)};
  --inline-video--play-btn--width: #{h.rem(90px)};
  --inline-video--play-btn--icon--font-size: #{h.rem(18px)};
  --inline-video--play-btn--border-radius: 0;
  --inline-video--play-btn--border: none;
  --inline-video--play-btn--opacity: .8;
  --inline-video--play-btn--contrained--bottom: 0;
  --inline-video--play-btn--contrained--left: 0;
  --inline-video--liveblog--margin: #{h.rem(24px) 0};

  @include h.breakpoint-m {
    --inline-video--info--height: #{h.rem(200px)};
    --inline-video--info--padding: #{h.rem(20px 64px 0 24px)};
    --inline-video--title--font-size: var(--text-24);
    --inline-video--detail--display: inline-block;
    --inline-video--detail--margin: var(--space-12) 0 0 0;
    --inline-video--share-list--width: #{h.rem(180px)};
    --inline-video--share-list--height: #{h.rem(46px)};
    --inline-video--share-icon--line-height: 10px;
    --inline-video--play-btn--height: #{h.rem(100px)};
    --inline-video--play-btn--width: #{h.rem(150px)};
    --inline-video--play-btn--icon--font-size: #{h.rem(29px)};
  }

  @include h.breakpoint-l {
    --inline-video--info--height: #{h.rem(220px)};
    --inline-video--play-btn--height: #{h.rem(80px)};
    --inline-video--play-btn--width: #{h.rem(120px)};
    --inline-video--play-btn--icon--font-size: #{h.rem(23px)};
    --inline-video--share-list--height: #{h.rem(60px)};
    --inline-video--share-icon--line-height: 22px;

    .article-hero__video & {
      --inline-video--play-btn--height: #{h.rem(120px)};
      --inline-video--play-btn--width: #{h.rem(180px)};
      --inline-video--play-btn--icon--font-size: #{h.rem(35px)};
    }
  }

  @include h.breakpoint-x {
    --inline-video--info--height: #{h.rem(120px)};
    --inline-video--play-btn--height: #{h.rem(100px)};
    --inline-video--play-btn--width: #{h.rem(150px)};
    --inline-video--play-btn--icon--font-size: #{h.rem(29px)};
    --inline-video--share-list--height: #{h.rem(46px)};
    --inline-video--share-icon--line-height: 10px;

    .article-hero__video & {
      --inline-video--share-list--height: #{h.rem(67px)};
      --inline-video--share-icon--line-height: 27px;
    }
  }

  @include constrained-height {
    --inline-video--title--font-size: 1.125em;
    --inline-video--play-btn--height: #{h.rem(60px)};
    --inline-video--play-btn--width: #{h.rem(90px)};
    --inline-video--play-btn--icon--font-size: #{h.rem(18px)};
  }

  body.select & {
    --inline-video--info--padding: #{h.rem(18px 48px 0 24px)};
    --inline-video--title--font-family: var(--lato);
    --inline-video--title--font-size: #{h.rem(14px)};
    --inline-video--title--font-weight: 700;
    --inline-video--title--line-height: #{h.rem(14px)};
    --inline-video--detail--font-family: var(--lato);
    --inline-video--detail--font-size: #{h.rem(12px)};
    --inline-video--detail--letter-spacing: 0;
    --inline-video--share-list--background-color: var(--blue-60);

    @include h.breakpoint-m {
      --inline-video--info--padding: #{h.rem(28px 64px 0 24px)};
      --inline-video--title--font-size: #{h.rem(24px)};
      --inline-video--title--line-height: #{h.rem(30px)};
      --inline-video--detail--line-height: #{h.rem(20px)}; // check mobile
      --inline-video--detail--margin: #{h.rem(8px)} 0 0 0; // check mobile
    }
  }

  body.today & {
    --inline-video--info--color: var(--white);
    --inline-video--info--background-image:
      linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0) 96.8%
      );
    --inline-video--info--height: auto;
    --inline-video--info--padding: #{h.rem(16px 48px 40px 16px)};
    --inline-video--title-color: var(--white);
    --inline-video--title-button--color: var(--white);
    --inline-video--title--font-family: var(--secondary-font);
    --inline-video--title--font-weight: 700;
    --inline-video--title--font-smoothing: auto;
    --inline-video--title--font-size: #{h.rem(14px)};
    --inline-video--title--line-height: 1.5;
    --inline-video--title--muted--margin: #{0 0 0 h.rem(64px)};
    --inline-video--detail--font-family: var(--secondary-font);
    --inline-video--detail--line-height: 1.5;
    --inline-video--detail--letter-spacing: 0;
    --inline-video--detail--font-weight: 700;
    --inline-video--detail--margin: #{h.rem(10px)} 0 0 0;
    --inline-video--detail--display: inline-block;
    --inline-video--detail--muted--margin-left: #{h.rem(64px)};
    --inline-video--play-btn--height: #{h.rem(71px)};
    --inline-video--play-btn--width: #{h.rem(71px)};
    --inline-video--play-btn--border-radius: var(--inline-video--play-btn--width);
    --inline-video--play-btn--border: 1px solid var(--primary-color);
    --inline-video--play-btn--opacity: 1;
    --inline-video--share-list--background-color: transparent;
    --inline-video--share-list--width: #{h.rem(144px)};
    --inline-video--share-list--height: #{h.rem(54px)};
    --inline-video--share-muted--margin-top: 0;
    --inline-video--play-btn--contrained--bottom: auto;
    --inline-video--play-btn--contrained--left: auto;

    @include h.breakpoint-m {
      --inline-video--info--padding: #{h.rem(20px 64px 40px 24px)};
      --inline-video--title--font-size: #{h.rem(24px)};
      --inline-video--detail--margin: 0 0 0 0;
      --inline-video--share-icon--line-height: 10px;
      --inline-video--play-btn--height: #{h.rem(125)};
      --inline-video--play-btn--width: #{h.rem(125)};
      --inline-video--title--muted--margin: #{h.rem(100px) 0 0 0};
      --inline-video--detail--muted--margin-left: 0;
    }

    @include constrained-height {
      --inline-video--play-btn--height: #{h.rem(60px)};
      --inline-video--play-btn--width: #{h.rem(60px)};
    }

    .inline-video__player:hover {
      --inline-video--play-btn--border: none;
    }
  }

  body.today .article-hero__video & {
    @include h.breakpoint-l {
      --inline-video--play-btn--height: #{h.rem(125)};
      --inline-video--play-btn--width: #{h.rem(125)};
      --inline-video--title--font-size: #{h.rem(24px)};
      --inline-video--share-list--width: #{h.rem(183px)};
      --inline-video--share-list--height: #{h.rem(66px)};
      --inline-video--detail--margin: 0;
      --inline-video--info--background-image:
        linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.8) 0%,
          rgba(0, 0, 0, 0) 96.8%
        );
    }

    @include h.breakpoint-x {
      --inline-video--share-icon--line-height: 10px;
    }
  }
}
