@use 'assets/styles/utils/helper' as h;

.share {
  --social-share-inline--hover-bkg: rgba(0, 0, 0, 0.3);
  --social-share-inline--dot--bkg: var(--white);
  --social-share-inline--line--stroke: var(--white);
  --social-share-inline--line--stroke-width: 1px;
  --social-share-inline--share-list--bkg: var(--default-color);
  --social-share-inline--share-list--color: var(--white);
  --social-share-inline--share-list--color-hover: var(--white);
  --social-share-inline--share-list--height: #{h.rem(40px)};
  --social-share-inline--link--margin: 0;
  --social-share-inline--link--padding: #{h.rem(14px)};
  --social-share-inline--link--border: none;
  --social-share-inline--link--bkg: transparent;
  --social-share-inline--link--bkg-hover: transparent;
  --social-share-inline--link--border-radius: 0;
  --social-share-inline--link--width: #{h.rem(40px)};
  --social-share-inline--link--transform: translateY(var(--social-share-inline--link--width));
  --social-share-inline--link--transition: transform 0.1s ease-in-out;
  --social-share-inline--link--font-size: #{h.rem(12px)};
  --social-share-inline--list-expanded--width: #{h.rem(120px)};
  --social-share-inline--link--not-hover--opacity: .5;
  --social-share-inline--link--delay-2: 0.1s;
  --social-share-inline--link--delay-3: 0.2s;
  --social-share-inline--link--delay-4: 0.3s;
  --social-share-inline--link-expanded-delay-1: 0.9s, 0s;
  --social-share-inline--link-expanded-delay-2: 1s, 0s;
  --social-share-inline--link-expanded-delay-3: 1.1s, 0s;
  --social-share-inline--link-expanded-delay-4: 1.2s, 0s;
  --social-share-inline--icon--line-height: #{h.rem(14px)};

  @include h.breakpoint-m {
    --social-share-inline--link--padding: #{h.rem(20px)};
    --social-share-inline--link--width: #{h.rem(60px)};
    --social-share-inline--link--font-size: #{h.rem(15px)};
    --social-share-inline--share-list--height: #{h.rem(60px)};
    --social-share-inline--list-expanded--width: #{h.rem(180px)};
    --social-share-inline--icon--line-height: #{h.rem(20px)};
  }

  :global(body.today) & {
    --social-share-inline--hover-bkg: transparent;
    --social-share-inline--dot--bkg: var(--white);
    --social-share-inline--line--stroke: var(--white);
    --social-share-inline--line--stroke-width: 2px;
    --social-share-inline--share-list--bkg: transparent;
    --social-share-inline--share-list--color: var(--secondary-30);
    --social-share-inline--share-list--height: #{h.rem(54px)};
    --social-share-inline--link--margin: #{h.rem(10px 0 0 4px)};
    --social-share-inline--link--padding: #{h.rem(15px) 0 h.rem(12px)};
    --social-share-inline--link--border: 1px solid var(--secondary-30);
    --social-share-inline--link--bkg: rgba(255, 255, 255, 0.8);
    --social-share-inline--link--border-radius: #{h.rem(44px)};
    --social-share-inline--link--width: #{h.rem(44px)};
    --social-share-inline--link--transform: translateX(#{h.rem(180px)});
    --social-share-inline--link--transition: transform 0.3s ease-in-out;
    --social-share-inline--link--font-size: #{h.rem(10px)};
    --social-share-inline--list-expanded--width: #{h.rem(144px)};
    --social-share-inline--link--not-hover--opacity: 1;
    --social-share-inline--link--bkg-hover: var(--secondary-30);
    --social-share-inline--share-list--color-hover: var(--white);
    --social-share-inline--link--delay-2: 0s;
    --social-share-inline--link--delay-3: 0s;
    --social-share-inline--link--delay-4: 0s;
    --social-share-inline--link-expanded-delay-1: 0s;
    --social-share-inline--link-expanded-delay-2: 0s;
    --social-share-inline--link-expanded-delay-3: 0s;
    --social-share-inline--link-expanded-delay-4: 0s;
    --social-share-inline--icon--line-height: #{h.rem(15px)};
  }

  :global(body.today .article-hero__video) & {
    @include h.breakpoint-l {
      --social-share-inline--link--width: #{h.rem(56px)};
      --social-share-inline--link--padding: #{h.rem(22px) 0 h.rem(19px)};
      --social-share-inline--link--font-size: #{h.rem(11px)};
      --social-share-inline--link--margin: #{h.rem(10px 0 0 5px)};
      --social-share-inline--list-expanded--width: #{h.rem(183)};
    }
  }
}
