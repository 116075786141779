@use 'assets/styles/utils/helper' as h;

/* stylelint-disable at-rule-no-unknown  */
/* stylelint-disable scss/at-rule-no-unknown  */
/* stylelint-disable no-descending-specificity  */

.background {
  opacity: 0;
  position: relative;
  width: 100%;
  height: 74px;
  border-top: 1px solid transparent;
  margin-top: -1px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 5.64%, rgba(0, 0, 0, 0.0001) 96.8%);
  transition: opacity 0.3s ease;
  pointer-events: none;
  padding-bottom: 4rem;

  @include h.breakpoint-m-max {
    padding-bottom: 2rem;
  }

  .share {
    position: static;
    float: right;
    padding-left: 12px;
  }
}

.title {
  margin: var(--video-title-margin);
  -webkit-font-smoothing: var(--video-player--title--font-smoothing);
  text-shadow: var(--video-player--text-shadow);
  color: var(--white);
  font-family: var(--video-player--title--font-family);
  font-style: var(--video-player--title--font-style);
  font-weight: var(--video-player--title--font-weight);
  line-height: var(--video-player--title--line-height);
  font-size: 16px;

  @container infoLayer (min-width: 960px) {
    margin: var(--video-title-margin-large);
  }

  @container infoLayer (min-width: 459px) {
    font-size: 20px;
  }
}

.background.show {
  .title {
    pointer-events: auto;
  }
  opacity: 1;
}

@media (pointer: fine) {
  :global(.video-container):hover .background {
    opacity: 1;

    .title {
      pointer-events: auto;
    }
  }
}

.overlayText {
  // these two match values from VideoUnmuteButton/theme.tokens.scss
  --expectedUntapButtonWidth: #{h.rem(44px)};
  --expectedUntapTopLeft: #{h.rem(10px)};
  // vars for title and detail sections
  --vtd-top-margin: var(--expectedUntapTopLeft);
  --vtd-top-margin-large: var(--vtd-top-margin);
  --vtd-mid-margin: #{h.rem(4px)};
  --vtd-after-margin: #{h.rem(4px)};
  --overlay-text-margin-left: 0;
  --overlay-text-margin-left-large: 0;
  --video-title-detail-left-right-margin: var(--expectedUntapTopLeft);
  --video-title-margin:
    var(--vtd-top-margin)
    var(--video-title-detail-left-right-margin)
    var(--vtd-mid-margin);
  --video-title-margin-large:
    var(--vtd-top-margin-large)
    var(--video-title-detail-left-right-margin)
    var(--vtd-mid-margin);
  --video-detail-margin:
    0
    var(--video-title-detail-left-right-margin)
    var(--vtd-after-margin);
  clear: left;
  display: flex;
  flex-direction: column;
  margin-left: var(--overlay-text-margin-left);

  @container infoLayer (min-width: 960px) {
    margin-left: var(--overlay-text-margin-left-large);
  }
}

.multistoryline {
  .overlayText {
    .title {
      margin-right: 0;
      text-align: left;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* Number of lines */
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: clip;
      word-wrap: break-word;
    }
  }
}

.notStarted {
  display: none;
}

.detail {
  margin: var(--video-detail-margin);
  text-shadow: var(--video-player--text-shadow);
  color: var(--white);
  font-family: var(--video-player--detail--font-family);
  font-size: var(--video-player--detail--font-size);
  font-weight: var(--video-player--detail--font-weight);
  letter-spacing: var(--video-player--detail--letter-spacing);
  line-height: var(--video-player--detail--line-height);
  text-transform: var(--video-player--detail--text-transform);

  span:not(:last-child)::after {
    content: ' / ';
  }
}

:global(.jw-flag-small-player ~ .info-layer) {
  .overlayText {
    @include h.breakpoint-m {
      margin-top: 0.5em;
    }
    clear: none;
  }

  .title {
    margin: 0.5rem 1rem;
    font-size: 16px;
    text-wrap: nowrap;

    @supports (-webkit-line-clamp: 2) and (-webkit-box-orient: vertical) and (display: -webkit-box) {
      white-space: nowrap;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      text-wrap: nowrap;
      -webkit-box-orient: vertical;
    }

    a {
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;
    }
  }

  .detail {
    display: none;
  }
}

// When the Tap to Unmute button is visible, we need to adjust the margin of the overlay text
// to account for the button width.
:global(.videoPlayer__unmute.unmuteTap) + .background .overlayText {
  --overlay-text-margin-left: calc(var(--expectedUntapTopLeft) + var(--expectedUntapButtonWidth));
  --overlay-text-margin-left-large: var(--overlay-text-margin-left);
}

:global(.jw-breakpoint--1 ~ .info-layer) .share {
  display: none;
}

:global(.jw-breakpoint-0 ~ .info-layer),
:global(.jw-breakpoint--1 ~ .info-layer) {
  .title,
  .detail,
  :global(.unmuteTap) {
    display: none;
  }

  .background,
  .background.show {
    opacity: 0;
  }
}
